import React from 'react'
import './achievement.scss'
import aboutThumb1 from 'assets/achievement-1.png';
import aboutThumb2 from 'assets/achievement-2.png';
import aboutThumb3 from 'assets/achievement-3.png';
import aboutThumb4 from 'assets/achievement-4.png';
import aboutThumb5 from 'assets/achievement-5.png';
import aboutThumb6 from 'assets/achievement-6.png';
import Grid from '@material-ui/core/Grid';
import plus_image from '../../../assets/plus_get_touch.png';
import image_gettouch from '../../../assets/image_get_touch.png';
import Button from '@mui/material/Button';
import Container from '@material-ui/core/Container';
import ImageSlider from '../../../staticcomponents/image-slider/index';

export default function Achievement() {
  const ImgArray= [aboutThumb1,aboutThumb2,aboutThumb3,aboutThumb4,aboutThumb5]
  return (
    <>
     <Container maxWidth="xl" className='careers-achievement-container'>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-about-cls">
      <ImageSlider ImageArray={ImgArray}/>
      </Grid>
      </Container>
     

    </>
  )
}



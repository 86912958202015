import React from "react";
import './people-culture-environment.scss';
import Grid from '@material-ui/core/Grid';
import Image4 from './../../../assets/Image-4.png';
import ImageGrid1 from './../../../assets/Image-grid1.png';
import ImageGrid2 from './../../../assets/Image-grid2.png';
import ImageGrid3 from './../../../assets/Image-grid3.png';
import Container from '@material-ui/core/Container';

export default function PeopleCultureEnvironment() {
  return (
    <>
      <Container maxWidth="xl" className="people-culture-environment">
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='first-row'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h2 className="heading" item xl={6} lg={6} md={6} sm={12} xs={12}>people, culture, environment</h2>
            <h3 className="sub-title">cartus a synonymn, for mobility business</h3>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='col-wrap'>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <h4 className='sec-title'>ethics</h4>
              <p className='desp1'>Year after year, our parent company, Anywhere, has been recognized as one of the World’s Most Ethical Companies® by the Ethisphere Institute, the global leader in defining and advancing the standards of ethical business.</p>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <h4 className='sec-title'>community</h4>
              <p className='desp2'>In neighborhoods around the world—across four continents, 12 countries, and 36 US states—Cartus employees are deeply rooted in helping those in need and support a number of charitable organizations, which is why the company offers up to one day off for volunteering each year</p>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className='mrgAuto'>
              <h4 className='sec-title'>diversity</h4>
              <p className='desp3'>Through our robust diversity, equity and inclusion strategy, we harness the power of global talent mobility to enable rewarding careers for the corporate professionals we move into and out of 190 countries around the world.</p>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='view-link'><a href="#.">VIEW EEO STATEMENT</a></Grid>
          </Grid>
        </Grid>

        {/* employee resource groups component */}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h4 className='sec-title sec-title2'>employee resource groups</h4>
        </Grid>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='left-col'>
          <Grid item xl={6} lg={7} md={9} sm={10} xs={12}>
            <p className='desp'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
            <p className='desp'>To support our strategic efforts in Diversity, Equity & Inclusion, we provide a formal mentoring program each year to empower employees of color to grow their careers and increase both the lateral and upward mobility of underrepresented talent, as well as annual Unconscious Bias training for all employees.</p>
          </Grid>
          <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
            <div className='img-box'>
              <img src={Image4} alt="Image" />
            </div>
          </Grid>
        </Grid>
        {/* employee resource groups component end*/}

        {/* home. hub. office. component start*/}

        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='right-col'>
          <Grid container item xl={7} lg={6} md={8} sm={12} xs={12}></Grid>
          <Grid item xl={5} lg={6} md={12} sm={12} xs={12} className='rgt-content'>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h4 className='sec-title'>home. hub. office.</h4>
            </Grid></Grid>
          <Grid container item xl={7} lg={6} md={8} sm={12} xs={12}>
            <div className='image-sec'>
              <img src={ImageGrid1} alt="Image" className='thumb-img1' />
              <img src={ImageGrid2} alt="Image" className='thumb-img2' />
              <img src={ImageGrid3} alt="Image" className="thumb-img3" />
            </div>
          </Grid>
          <Grid item xl={5} lg={6} md={12} sm={12} xs={12} className='rgt-content'>
            <p className='desp'>We think you’re great (you’re welcome!) But we want you to feel great too. Our global employee footprint means that our benefits offerings are positioned to meet the needs of our widely disbursed employees.</p>
            <p className='desp'>Work for us in our US region then count on medical dental, vision coverage, great PTO offering, 401k, savings accounts to support healthcare expenses, to name just a few of the great benefits on offer.</p>
            <p className='desp'>Alternatively as an employee based in our APAC or India region then you will benefit from enhanced private medical insurance, life insurance cover, and leave entitlement.</p>
            <p className='desp'>If EMEA is your home then you can expect private medical insurance, pension, life assurance, long term disability insurance and generous leave entitlements as standard.</p>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}



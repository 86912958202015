import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import './career-header.scss';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import our_solutions_img from '../../../assets/aboutus-Videoimg.png';
import circle_img from '../../../assets/career-hero-image.png';
import unique_factor_img1 from '../../../assets/career-mobility-growth-1.png';
import unique_factor_img2 from '../../../assets/career-mobility-growth-2.png';
import Container from '@material-ui/core/Container';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Homeslider() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <grid for image background> */}
      {/* <div className='career-header-main-sec'>
      <Container maxWidth="xl" className='career-header-cls'>

      <Grid className='careers-header-cls'>
        <Grid container className='slider-container'>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <div className='slider-image'>
              <img src={circle_img} alt="where mobility meets agility" width="100%" />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="text-center">
            <div>
              <h1 className='title'>careers</h1>
              <p>67 years of rich heritage and unrelenting
                dedication to transform employee experience</p>
            </div>
          </Grid>

        </Grid>
      </Grid>
      </Container></div> */}
       <div className='aboutus-hero'>
    <div className='aboutus-header-cls'>
      {/* <grid for image background> */}
      <Grid className='header-div'>
        <Grid container className='header-container'>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className='header-hero-bg'>   
          {/* <img src={aboutus_heroimg} alt="About us"></img>          */}
            <div className='blue-round-div'>
              <h1 className='title'>careers</h1>
              <p className='blue-sec-desp'>professional (and personal!) development and growth through a connected culture of high performance and winning behaviors</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
    </div>

      {/* <feel secure with cartus section> */}
      <Container maxWidth="xl" className='career-header-cls'>
      {/* <Grid container className='professional-development-container'>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <div className='image-box'>
            <Paper elevation={0} className="video-container">
              <Button className="button-container" onClick={handleClickOpen}>
                <img src={unique_factor_img1}
                  alt="video"
                  className="video-img" />
                <PlayCircleIcon fontSize="large" className="play-button-icon" />
              </Button>
              <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                <Box className="popup-overlay">
                  <IconButton className="popup-close-icon" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent >
                    <Grid container className='dialog-content'>
                      <div className="iframe-class">
                        <iframe
                          width="560"
                          height="349"
                          className="responsive-iframe"
                          src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                      </div>
                    </Grid>
                  </DialogContent>
                </Box>
              </Dialog>
              <Grid className='text-right'>
                <Paper elevation={0}>
                  <Typography className="video-title">our unique factor</Typography>
                  <Typography className="video-subtitle">welcome to the world of cartus</Typography>
                </Paper>
              </Grid>

            </Paper>
          </div>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={1} xl={1}></Grid>
        <Grid item container xs={12} sm={12} md={12} lg={6} xl={6} className="video-txt-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2 className='title1'>professional development and growth</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className='sub-title'>cartus a synonymn, for mobility business</h3>
            <p>Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla.
              Etiam consectetur maximus porta. Cras sit amet efficitur tortor, at placerat
              tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse
              consectetur venenatis neque, quis vehicula augue pretium id.
              Sed finibus pretium tortor sagittis placerat. Sed vitae libero vestibulum,
              dictum turpis in, lobortis libero. In commodo vitae diam vel scelerisque.
              Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur
              maximus porta. Cras sit amet efficitur tortor, at placerat tortor.
              Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur
              venenatis neque, quis vehicula augue pretium id.</p>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container className='career-txt-with-video-container'>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='header-intro-div'>
        <Grid item xs={6} sm={5} md={5} lg={7} xl={6}>
          <h2 className='title'>grow your career with an industry leader</h2>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
          <h3 className='sub-title'>connecting talent. seizing opportunities. building our future.</h3>
          <p className='description'>It’s a new day for Cartus! We are making bold strategic moves to be the leading force in our industry, driven by talented individuals across our integrated company. We expect every employee to be the reason we attract and keep phenomenal people by empowering themselves and others to continually learn, improve, seek diversity, and thrive. We expect every employee to be the reason we attract, and keep phenomenal people by empowering themselves</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
          <div className='image-box'>
            <Paper elevation={0} className="video-content">
              <Button className="button-container" onClick={handleClickOpen}>
                <img src={unique_factor_img1}
                  alt="video"
                  className="video-img" />
                <PlayCircleOutlineIcon fontSize="large" className="play-button-icon" />
              </Button>
              <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                <Box className="popup-overlay">
                  <IconButton className="popup-close-icon" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent >
                    <Grid container className='dialog-content'>
                      <div className="iframe-class">
                        <iframe
                          width="560"
                          height="349"
                          className="responsive-iframe"
                          src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                      </div>
                    </Grid>
                  </DialogContent>
                </Box>
              </Dialog>
              <Grid>
                <Paper elevation={0}>
                  <Typography className="video-title natom-medium">our unique factor</Typography>
                  <Typography className="video-subtitle">welcome to the world of cartus</Typography>
                </Paper>
              </Grid>
            </Paper>
          </div>
        </Grid>
        </Grid>
        </Grid>
      </Grid>

      <Grid container className='career-image-container'>
      
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className='img-box'>
            <img src={unique_factor_img2} alt="image" className="text-img" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <p>Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis.
            Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur
            maximus porta. Cras sit amet efficitur tortor, at placerat tortor.
            Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur
            venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor
            sagittis placerat. Sed vitae libero vestibulum, dictum turpis in,
            lobortis libero. In commodo vitae diam vel scelerisque. Sed eget diam eu
            urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta.
            Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis.
            Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>
        </Grid>
      </Grid>
      </Container>
    
    </>
  )
}



import React from 'react'
import Layout from '../../staticcomponents/layout'
import './careers.scss'
import CareersHeader from './career-header'
import AboutusOurWinning from './../aboutus/aboutus-our-winning'
import PeopleCultureEnvironment from './people-culture-environment'
import CareerOurQuote from './career-our-quote'
import WonderfulBenefits from './our-wonderful-benefits'
import Achievement from './achievement'
import JoinOurTeam from './join-our-team'

export default function Careers() {
    return (
        <>
            <Layout>
                <div className='careers-main-cls'>
                    <div className='bg-donuts-left'>
                        <CareersHeader />
                        <div className='bg-donuts-right'>
                            <AboutusOurWinning />
                            <PeopleCultureEnvironment />
                            <CareerOurQuote />
                            <WonderfulBenefits />
                            <Achievement />
                            <JoinOurTeam />
                        </div>
                    </div>

                </div>
            </Layout>
        </>
    )
}
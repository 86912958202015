import React, { useState } from 'react';
import './our-wonderful-benefits.scss';
import Grid from '@material-ui/core/Grid';
import { Button, Box } from '@material-ui/core';
import wonderful_benifits from '../../../assets/wonderful-benifits-career.png';
import unrelenting from '../../../assets/unrelenting-icon.png';
import health_wellness from '../../../assets/career-health-wellness-img.png';
import Container from '@material-ui/core/Container';

// import capsule from '../../../assets/capsules.png';

import whycartus_video_thumbnail from '../../../assets/whycartus-video-thumbnail.png';



export default function WonderfulBenefits() {

  return (
    <>

      <Container maxWidth="xl" className='wonderful-benefits-cls'>        
        <Grid className='txt-with-container'>
          <Grid container className='title-with-icon'>
            <Grid item xs={8} sm={8} md={5} lg={5} xl={4}>
              <Grid className='text-img-align'>
              <img src={unrelenting} className="img-allign" />
              <p className='title1'>our wonderful benefits</p>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='sub-title'>guiding you and your health forward</div>
              <div className='sec-title'>personal and professional development</div>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='left-col'>
            <Grid item xl={6} lg={7} md={9} sm={12} xs={12} className="leftcol-top-space">
              <p className='desp'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
              <p className='desp'>To support our strategic efforts in Diversity, Equity & Inclusion, we provide a formal mentoring program each year to empower employees of color to grow their careers and increase both the lateral and upward mobility of underrepresented talent, as well as annual Unconscious Bias training for all employees.</p>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              <div className='image-box'>
                <img src={wonderful_benifits} alt="Image" />
              </div>
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={7} className='subtitles-allign'>
              <p className='description'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
              <p className='description'>To support our strategic efforts in Diversity, Equity & Inclusion, we provide a formal mentoring program each year to empower employees of color to grow their careers and increase both the lateral and upward mobility of underrepresented talent, as well as annual Unconscious Bias training for all employees.</p>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4} xl={5}>
              <div className='image-box-career'>
                <div className="">
                  <img src={wonderful_benifits} className="wonderfull_benifit" />
                </div>
              </div>
            </Grid>
          </Grid> */}
        </Grid>
        {/* <Grid container className='txt-with-container'>
           
           
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={8} sm={7} md={5} lg={5} xl={4} className='title-allign'>
              <img src={unrelenting} className="img-allign" />
              <p className='title1'>our wonderful benefits</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={7} className='subtitles-allign'>
                <div className='sub-title'>guiding you and your health forward</div>
                <div className='sub-title2'>personal and professional development</div>

                <p className='description'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
                <p className='description'>To support our strategic efforts in Diversity, Equity & Inclusion, we provide a formal mentoring program each year to empower employees of color to grow their careers and increase both the lateral and upward mobility of underrepresented talent, as well as annual Unconscious Bias training for all employees.</p>
              </Grid>
              </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
             
              <Grid item xs={12} sm={12} md={12} lg={4} xl={5}>
                <div className='image-box-career'>
                  <div className="">

                    <img src={wonderful_benifits} className="wonderfull_benifit" />
                  </div>
                </div>
              </Grid>
            </Grid>
          
        </Grid> */}

        {/* employee resource groups component */}
        {/* <Grid className='txt-with-container'>
        
        <Grid item xs={8} sm={7} md={5} lg={5} xl={4} className='title-allign'>
              <img src={unrelenting} className="img-allign" /><p className='title1'>our wonderful benefits</p>
        </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <h4 className='sec-heading'>guiding you and your health forward</h4>
        <h4 className='sec-title'>personal and professional development</h4>
      </Grid>
      </Grid> */}


        {/* employee resource groups component end*/}

        {/* health and wellness component */}
        <Grid container className='health-wellness-cls'>

          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={6} lg={5} md={12} sm={12} xs={12}></Grid>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
              <div className='right-header-sec'>
                <h4 className='sec-title'>health and wellness</h4>
              </div>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              <div className='image-box'>
                <img src={health_wellness} alt="Image" />
              </div>
            </Grid>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
              <div className='right-text-sec'>
                <p className='desp'>We think you’re great (you’re welcome!) But we want you to feel great too. Our global employee footprint means that our benefits offerings are positioned to meet the needs of our widely disbursed employees.</p>
                <p className='desp'>Work for us in our US region then count on medical dental, vision coverage, great PTO offering, 401k, savings accounts to support healthcare expenses, to name just a few of the great benefits on offer.</p>
                <p className='desp'> Alternatively as an employee based in our APAC or India region then you will benefit from enhanced private medical insurance, life insurance cover, and leave entitlement.</p>
                <p className='desp'> If EMEA is your home then you can expect private medical insurance, pension, life assurance, long term disability insurance and generous leave entitlements as standard.</p>
              </div>
            </Grid>

          </Grid>
        </Grid>

        {/* health wellness sub component */}
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='health-wellness-sebsection-cls'>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <h4 className='sec-title'>wellness programs</h4>
            <p className='desp1'>This includes Medical, Dental and Vision, with several coverage options for employees and their eligible dependents. The plans are designed to balance broad coverage with affordability.</p>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <h4 className='sec-title'>employee assistance program</h4>
            <p className='desp2'>Taxed advantage Health Savings Accounts (HSAs) that can be used to pay for qualified healthcare expenses. Money saved rolls over from year to year and can be used to save for retirement. Tax free Flexible Spending Accounts (FSAs) for Dependent Care expenses related to the care of young.</p>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="desp3-auto">
            <h4 className='sec-title'>401k retirement planning</h4>
            <p className='desp3'>These savings plans encourage employees' tax-favored retirement saving. A wide variety of investment options are offered.</p>
          </Grid>

        </Grid>


        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='text-capsule-container'>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='text-capsule-container-bg'>
            <Grid item xs={12} sm={12} md={9} lg={8} xl={7}>
              <div className='sub-title-capsule'>we are dedicated to our objective of providing anywhere employees with compelling, comprehensive and cost-effective employee benefit offerings. anywhere is committed to providing physical activity and wellness opportunities for our employees.</div>
            </Grid>
            <Grid item xs={12} sm={2} md={3} lg={3} xl={5}>
              {/* <div className='image-box-career'>
              <div className="">
                <img src={capsule} className="text-capsule" />
              </div>
            </div> */}
            </Grid>
          </Grid>
        </Grid>
 </Container>
      
      
    </>
  )
}



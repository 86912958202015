import React from "react";
import './join-our-team.scss';
import Grid from '@material-ui/core/Grid';
import plus_image from '../../../assets/plus_get_touch.png';
import image_gettouch from '../../../assets/image_get_touch.png';
import Button from '@mui/material/Button';
import Container from '@material-ui/core/Container';


export default function JoinOurTeam() {
  return (
    <>
      <div className='career-main-sec'>
      <Container maxWidth="xl" className='career-joinus-container'  >
        <Grid className='career-join-cls'>
          <Grid container className="secure-container-about" >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <img className="plus-image" src={plus_image} />
              <h2 className="title-get-touch">join our team</h2>
              <h3 className="sub-title-get-touch">I want to join the Cartus team</h3>
              <p>If you are talented, curious, and interested in building the future of global talent mobility with an industry leader, please apply to join our team!</p>
              <div>
                <Button variant="outlined" className='btn-join-us'>Join us</Button>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="right-image-div">
              <img className="image-gettouch" src={image_gettouch} />

            </Grid>

          </Grid>
        </Grid>
      </Container>
      </div>
    </>
  )
}


